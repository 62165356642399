import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import StudentSidebar from "../../components/StudentSidebar";
import { fetchStudentId, fetchStudentStartupSimStatus, fetchStudentStartupSimResumeData } from "SimulatorAPIService";
import StartupProgressSteps from "components/StartupProgressSteps";
import StartupProgressBar from "components/StartupProgressBar";
import StartupOwnership from "components/StartupOwnership";
import StartupInfoItem from "components/StartupInfoItem";
import StartupDecision from "components/StartupDecision";
import StartupFinancials from "components/StartupFinancials";
import StartupTeam from "components/StartupTeam";
import StartupType from "components/StartupType";
import { Img } from "components";

export default function StartupDashboard() {
  // const [searchBarValue35, setSearchBarValue35] = useState("");
  const [studentId, setStudentId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [canStart, setCanStart] = useState(true);
  const [isStarted, setIsStarted] = useState(false);
  const [sessionData, setSessionData] = useState({});
  const [dashData, setDashData] = useState({});
  const [stageNumber, setStageNumber] = useState(0);
  const [countInStage, setCountInStage] = useState(0);
  const [totalInStage, setTotalInStage] = useState(0);
  const [stages, setStages] = useState({});
  const [formattedDate, setFormattedDate] = useState("");

  const navigate = useNavigate();

  const numberFormatOptions = { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = await fetchStudentId();

        if (id) {
          setStudentId(id);
          console.log(id)
          //format date
         
          
          const studentStatus = await fetchStudentStartupSimStatus(id);
          if(studentStatus.canStart === false){
            setCanStart(false);
          } else {
            if(studentStatus.started === true){
              const resumeData = await fetchStudentStartupSimResumeData(id);
              if(resumeData){
                setSessionData(resumeData.session_info);
                setDashData(resumeData.progress);
                setStageNumber(resumeData.stage);
                setCountInStage(resumeData.count_in_stage);
                setTotalInStage(resumeData.total_in_stage);
                setStages(resumeData.stages);
                const date = new Date(resumeData?.progress.presentedUTC);
                const options = { day: 'numeric', month: 'long', year: 'numeric' };
                const formatted = date.toLocaleDateString('en-GB', options);
                setFormattedDate(formatted);
                setIsStarted(true);
              }
            } else {
              setIsStarted(false);
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/timeout");
        } else {
          console.error('Error fetching data:', error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    //console.log(dashData);
    //console.log(isStarted);
    //console.log(stages);
  },[dashData])

  return (
    <>
      <Helmet>
        <title>Startup Sim Dashboard | Intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>

      <div className="flex w-full h-screen overflow-hidden m-auto">

        <div><StudentSidebar /></div>
        {!isLoading ? (
          <div className="flex flex-col w-full bg-slate-100 pb-10">
          {isStarted ? (
          <>
          <StartupProgressSteps sessionData={sessionData} dashData={dashData} stageNumber={stageNumber} />
          
          <div className="flex rounded-lg border border-solid border-slate-300 bg-white-A700 sm:flex-col m-3 h-full" style={{overflow: "scroll"}}>
            <div className="flex flex-col w-2/3 h-full border-r border-solid border-slate-300">
              <div className="m-3">
                <StartupProgressBar sessionData={sessionData} dashData={dashData} stageNumber={stageNumber} stages={stages} />
              </div>
              <div className="flex flex-row h-full">
                <div className="flex flex-col grow-0 w-1/4 h-full">
                  <div className="mx-3 mb-3">
                    <StartupInfoItem title="Current Market Value" value={new Intl.NumberFormat('en-US', numberFormatOptions).format(dashData?.currentMarketValue)} description="The current valuation of your business in the market" />
                  </div>
                  <div className="mx-3 mb-3">
                    <StartupInfoItem title="Employee Wellness" value={dashData?.employeeWellness + "/100"} description="Measure of your team’s morale, health, and engagement"/>
                  </div>
                  <div className="mx-3 mb-3">
                    <StartupInfoItem title="Gross Revenue" value={new Intl.NumberFormat('en-US', numberFormatOptions).format(dashData?.grossRevenue)} valueClass={(dashData?.grossRevenue.toString().startsWith("-") ? "!text-red-500" : "!text-green-500")} description="Total income generated from business operations before expenses"/>
                  </div>
                  <div className="mx-3 mb-3">
                    <StartupInfoItem title="Profit/Loss" value={new Intl.NumberFormat('en-US', numberFormatOptions).format(dashData?.profitLoss)} valueClass={(dashData?.profitLoss.toString().startsWith("-") ? "!text-red-500" : "!text-green-500")} description="The net financial outcome after subtracting costs from revenue"/>
                  </div>
                </div>
                <div className="flex flex-col grow w-3/4 h-full">
                  <div className="mr-3 mb-3">
                      <StartupDecision studentId={studentId} sessionData={sessionData} setSessionData={setSessionData} stageNumber={stageNumber} setStageNumber={setStageNumber} data={dashData} setData={setDashData} date={formattedDate} setDate={setFormattedDate} />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-1/3 h-full">
              <div className="flex-row mx-3 my-3">
                <StartupOwnership />
              </div>
              <div className="h-1/2 mx-3 mb-3">
                <StartupFinancials />
              </div>
              <div className="h-1/4 mx-3 mb-3">
                <StartupTeam />
              </div>
            </div>
          </div>
          </>
          ) : (
            !canStart ? (
              <div className="flex flex-row h-full">
              <div className="flex flex-col grow w-3/4 h-full">
                <div className="mr-3 mb-3">
                  The teacher for your class has not yet activated the Startup Simulator for you.
                </div>
              </div>
            </div>
            ) : (
              <div className="flex flex-row h-full">
                <div className="flex flex-col grow w-3/4 h-full">
                  <div className="mr-3 mb-3">
                    <StartupType studentId={studentId} sessionData={sessionData} setSessionData={setSessionData} stageNumber={stageNumber} setStageNumber={setStageNumber} setIsStarted={setIsStarted} setData={setDashData} />
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        ) : (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}>
            <Img src="/images/loading.gif" style={{
              maxWidth: '100%', // Ensures the image scales properly
              maxHeight: '100%'
            }}/>
          </div>
        )}
      </div>
    </>
  );
}

