import React, {useEffect, useState} from "react";
import { Button } from "..";
import { IconAlertCircle } from "@tabler/icons-react";
import { fetchStatus, postSettings } from "StartupSimAPIService";
import { useParams } from 'react-router-dom';
import NotFound from "pages/NotFound";

export default function StartupSimProgress({progressChange, setShowPopup}) {
  const [changedMetric, setChangedMetric] = useState("")
  const [arrowDirection, setArrowDirection] = useState("");
  const [direction, setDirection] = useState("");
  const [changedBy, setChangedBy] = useState(0);
  const [changedByWord, setChangedByWord] = useState("");

  const numberFormatOptions = { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 };
  
  useEffect(() => {
    let _changedMetric = progressChange.changed;
    setChangedMetric(_changedMetric);

    if(progressChange.now > progressChange.was){
      setArrowDirection("arrow-up");
      setDirection("increased");
    } else {
      setArrowDirection("arrow-down");
      setDirection("decreased");
    }

    if(_changedMetric !== "Employee Wellness"){
      setChangedBy(new Intl.NumberFormat('en-US', numberFormatOptions).format(Math.abs(progressChange.now - progressChange.was)));
      setChangedByWord("");
    } else {
      setChangedBy(Math.abs(progressChange.now - progressChange.was));
      setChangedByWord("points");
    }

  },[])

  return (
    <div className="p-4">
    <div className="flex flex-col gap-3">
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div class={arrowDirection}></div>
    </div>
        <h2 className="text-center">Your {changedMetric} {direction} by {changedBy} {changedByWord}</h2>
        <div className="flex gap-4 mt-4">
          <Button
            size="3xl"
            variant="outline"
            shape="round"
            className="w-full min-w-[200px] bg-blue-700 font-roboto font-medium tracking-[0.10px] sm:px-5"
            onClick={() => setShowPopup(false)}
            >
            <span className="text-white-A700">
                OK
            </span>
          </Button>
        </div>
    </div>
</div>
  );
}
