import React, {useState, useContext} from "react";
import { Button } from "..";
import { IconArrowRight } from "@tabler/icons-react";
import { postBeginStartup } from "StartupSimAPIService";
import { UserContext } from 'UserContext';

const StartupType = ({studentId, setIsStarted, setData}) => {
  const [startupType, setStartupType] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { userInfo, setUserInfo } = useContext(UserContext);

  const handleSelectType = (type) => { 
      setStartupType(type);
      console.log(studentId)
  }

  const handleSetStartupType = async () => { 
    setIsSubmitting(true);
    console.log(userInfo)
    try {
      const response = await postBeginStartup(studentId, startupType).then((promiseResult) => {
        console.log(promiseResult);
        setData(promiseResult.progress);
        setIsStarted(true);
        setIsSubmitting(false);
      });
      // const response = await postStartupType(userInfo?.id, startupType);
      // const response = await postStartupType('08dcad71-d5db-491f-89e4-52da7ea4893a', startupType);
  
      return;
    } catch (error) {
      console.error('Error posting data:', error);
      setIsSubmitting(false);
      // setErrorMessage(error);
    }
  }

  return (
    <div className="grid grid-cols-12 mt-5">
      <div className="col-span-1"></div>
      <div className="col-span-10 p-5 m-5 rounded-lg border border-solid bg-white-A700 sm:flex-col">
          <div className="flex flex-col w-full rounded-b-lg">
            <div className="flex flex-col gap-3">
              <h1 className="text-3xl font-semibold pt-2">Every great business starts with a spark. What ignites yours?</h1>
              <h2 size="text-lg">Choose the industry that excites you the most</h2>
            </div>
            <div className="flex flex-row rounded-lg bg-white-A700 mt-10 mb-10 h-80">
              <div className={startupType === "service" ? "cursor-pointer col-span-3 mx-5 my-3 p-3 text-center border-2 border-solid border-blue-800 rounded-lg bg-slate-100 flex flex-col justify-center items-center" : "cursor-pointer col-span-3 mx-5 my-3 p-3 text-center border border-solid border-slate-300 rounded-lg flex flex-col justify-center items-center"} onClick={() => handleSelectType("service")}>
                <h1 className="text-3xl font-semibold py-4">Service Industry</h1>
                <h2 size="text-lg">Build a thriving service-based business like a restaurant.</h2>
              </div>
              <div className={startupType === "tech" ? "cursor-pointer col-span-3 mx-5 my-3 p-3 text-center border-2 border-solid border-blue-800 rounded-lg bg-slate-100 flex flex-col justify-center items-center" : "cursor-pointer col-span-3 mx-5 my-3 p-3 text-center border border-solid border-slate-300 rounded-lg flex flex-col justify-center items-center"} onClick={() => handleSelectType("tech")}>
                <h1 className="text-3xl font-semibold py-4">Tech Industry</h1>
                <h2 size="text-lg">Develop cutting-edge software, apps, or tech solutions.</h2>
              </div>
              <div className={startupType === "product" ? "cursor-pointer col-span-3 mx-5 my-3 p-3 text-center border-2 border-solid border-blue-800 rounded-lg bg-slate-100 flex flex-col justify-center items-center" : "cursor-pointer col-span-3 mx-5 my-3 p-3 text-center border border-solid border-slate-300 rounded-lg flex flex-col justify-center items-center"} onClick={() => handleSelectType("product")}>
                <h1 className="text-3xl font-semibold py-4">Product Industry</h1>
                <h2 size="text-lg">Create innovative physical products that solve problems.</h2>
              </div>
            </div>
            <div className="flex flex-row-reverse rounded-lg mt-5">
              <Button className="bg-blue-700 w-80 rounded-lg" size="6xl" disabled={startupType ? false : true} onClick={() => handleSetStartupType()} >
                <span className="text-white-A700">
                    {isSubmitting ? "Please wait..." : "Lets Launch your business!"}
                </span>
                <IconArrowRight className="ml-3" />
              </Button>       
            </div>
        </div>  
      </div>
      <div className="col-span-1">
      </div> 
    </div>
  )
}

export default StartupType;