import React, {useEffect, useState} from "react";
import { Button } from "..";
import { IconAlertCircle } from "@tabler/icons-react";
import { fetchStatus, postSettings } from "StartupSimAPIService";
import { useParams } from 'react-router-dom';

export default function StartupSimSettings() {
  const { classroomID } = useParams();
  const [selectedDifficulty, setSelectedDifficulty] = useState('')
  const [difficultySet, setDifficultySet] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  
  useEffect(() => {
    try {
      const response = fetchStatus(classroomID).then((promiseResult) => {
        console.log(promiseResult)
        setDifficultySet(promiseResult?.optionsSet)
        setIsLoading(false);
        return;
      });
    } catch (error) {
      console.error('Error fetching status:', error);
    }
  },[])

  const handleUpdateSettings = async () => {
    setIsSubmitting(true);

    const settingData = {
      difficulty: selectedDifficulty
    }

    try {
      const response = await postSettings(classroomID, settingData);
      console.log(response);
      setIsSubmitting(false);
      setDifficultySet(true);
      return;
    } catch (error) {
      console.error('Error posting trade:', error);
      setIsSubmitting(false);
      setErrorMessage(error);
    }
  };    

  return (
    <div className="p-4">
    <div className="flex flex-col gap-3">
        <h1 className="text-3xl font-semibold py-4">Startup Simulator Settings</h1>
        <h2 size="text-lg">Set settings for the startup simulator</h2>
    </div>

    {!isLoading && (
    <div className="flex flex-col gap-6 mt-6 text-left w-1/2">
        {/* Difficulty */}
        {difficultySet ? (
          <div className="flex flex-col">
            <label className="mb-1">Difficulty Already Set</label>
          </div>
        ) : (
          <>
            <div className="flex flex-col">
                <label className="mb-1">Difficulty</label>
                <div className="border rounded-lg">
                    <select
                        className="p-2 w-full"
                        value={selectedDifficulty}
                        onChange={(e) => setSelectedDifficulty(e.target.value)}
                        >
                        <option value="" disabled>Select difficulty</option>
                        <option value="easy" >Easy</option>
                        <option value="medium" >Medium</option>
                        <option value="hard" >Hard</option>
                    </select>
                </div>
            </div>

            {/* Buttons */}
            <div className="flex gap-4 mt-4">
              <Button
                  size="3xl"
                  variant="outline"
                  shape="round"
                  className="w-full min-w-[200px] bg-blue-700 font-roboto font-medium tracking-[0.10px] sm:px-5"
                  onClick={handleUpdateSettings}
                  disabled={isSubmitting}
                  >
                  <span className="text-white-A700">
                      {isSubmitting ? "Please wait..." : "Update"}
                  </span>
              </Button>
            </div>

            {/* Error Messages */}
            {errorMessage && !difficultySet && (  // Only show error if no difficulty set
              <div className="mt-4 p-4 text-red-600 bg-red-100 border border-red-400 rounded">
                <IconAlertCircle className="inline-block mr-2" />
                {errorMessage}
              </div>
            )}
          </>
      )}
    </div>
    )}
</div>
  );
}
