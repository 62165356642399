import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Img, Text, Button, Heading } from "../../components";
import Header from "../../components/Header";
import { ReactTable } from "../../components/ReactTable";
import StudentSidebar from "../../components/StudentSidebar";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router-dom";
import { fetchClassroomDetails } from "TeacherAPIService";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ReactTableHoldings } from "components/ReactTableHoldings";
import ClassPopup from "components/CreateClassPopup";
import AddCourseModal from "components/AddCourseModal";
import { fetchStudentStartupSimAllProgressData } from "SimulatorAPIService";
import Skeleton from "react-loading-skeleton"
import 'react-loading-skeleton/dist/skeleton.css'
import { Link } from "react-router-dom";
import StartupProgressList from "components/StartupProgressList";
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import { formatDate } from "utils";


export default function StudentStockSimulator({ studentId }) {
    const [startupSimData, setstartupSimData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (studentId) {
                    const startupSimData = await fetchStudentStartupSimAllProgressData(studentId);
                    setstartupSimData(startupSimData);
                }
            } catch (error) {
                console.error('Error fetching data or posting trade:', error);
            } finally {
                setIsLoading(false);
            }

        };

        fetchData();
    }, []);


    return (
        <div className="flex flex-col overflow-y-auto gap-4">

            <div className="flex">
                {/* <div className="w-[30%] border rounded-lg p-4 items-center justify-center">
                    <h2 className="font-semibold">Trading Dates</h2>
                    
                    <h2 className="flex h-full items-center justify-center text-center">Coming soon!</h2>
                </div> */}
                

                <div className="flex w-full flex-col gap-6 rounded-lg border border-solid border-blue_gray-50_01 bg-white-A700 p-4 ml-3 md:w-full">
                    <StartupProgressList startupSimData={startupSimData} height={250} />
                </div>
            </div>
        </div>
    );
};

