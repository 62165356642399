import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Img, Text, Button, Heading } from "../../components";
import { useNavigate, useParams } from 'react-router-dom';
import { fetchClassroomDetails } from "TeacherAPIService";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AssignedCourses from "components/AssignedCourses";
import Students from "components/Students";
import Instructors from "components/Instructors";
import Sidebar1 from "components/Sidebar1";
import HomeDate from "components/HomeDate";
import ShareClassCode from "components/ShareClassCode";
import StudentPerformance from "components/StudentPerformance";
import ClassroomPerformance from "components/ClassroomPerformance";
import Assignments from "components/Assignments";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { formatDate } from "utils";

export default function ClassroomDetails() {
    const navigate = useNavigate();
    const { classroomID } = useParams();
    const [classroomDetails, setClassroomDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSharingClassCode, setIsSharingClassCode] = useState(false);
    const [windowSize, setWindowSize] = useState(window.innerWidth);

    const fetchData = async () => {
        try {
            const fetchedClassroom = await fetchClassroomDetails(classroomID);
          console.log(fetchedClassroom)
            // If the fetch was successful, update the state with the classroom data
            setClassroomDetails(fetchedClassroom);
            console.log(fetchedClassroom)
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // If a 401 error is returned, navigate to the timeout page
                navigate("/timeout");
            } else {
                console.error('Error fetching classrooms:', error);
                setError(error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [classroomID]);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(window.innerWidth);
            document.querySelector(".overflow-hidden.md\\:overflow-auto").scrollTop = 0;
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            <Helmet>
                <title>Classroom | Intertwined</title>
                <meta name="description" content="Web site created using create-react-app" />
            </Helmet>
            <div className="flex md:flex-col h-screen overflow-hidden">
                <Sidebar1 />
                <div className="flex flex-col w-full max-h-[100vh] overflow-hidden">
                    <div className="flex justify-between">
                        <div className="flex items-center text-center gap-2 min-h-[50px] px-4 cursor-pointer" onClick={() => navigate('/teacherhome')}>
                            <Img src="/images/img_arrow_left_blue_a200.svg" alt="arrowleft" className="h-[20px]" />
                            <p className="text-md !text-blue-500">All Classes</p>
                        </div>
                        <HomeDate />
                    </div>

                    <div className="flex justify-between bg-gray-100 p-5">
                        <div className="flex flex-col gap-2">
                            {isLoading ? (
                                <Skeleton height={36} width={200} />
                            ) : (
                                <h1 className="text-3xl font-semibold">
                                    {classroomDetails?.name ?? 'N/A'}
                                </h1>
                            )}
                            {isLoading ? (
                                <Skeleton width={150} />
                            ) : (
                                <p className="text-xs text-gray-400">Created: {formatDate(classroomDetails?.createdDate) ?? 'Unknown'}</p>
                            )}
                        </div>
                        <div className="flex items-center justify-center gap-4">
                            <Button
                                size="3xl"
                                variant="outline"
                                shape="round"
                                className="min-w-[166px] font-roboto font-medium tracking-[0.10px] sm:px-5"
                                onClick={() => setIsSharingClassCode(true)}
                            >
                                Share Class Code
                            </Button>
                            {classroomDetails && (
                                <ShareClassCode
                                    open={isSharingClassCode}
                                    onClose={() => setIsSharingClassCode(false)}
                                    classTokenInfo={classroomDetails.classTokens?.sort((a, b) => new Date(b.generatedUtc) - new Date(a.generatedUtc))[0]} // GETTING MOST RECENT TOKEN
                                    className={classroomDetails.name}
                                    classroomID={classroomID}
                                />
                            )}
                            {/* <Button color="blue_gray_100" size="2xl" variant="outline" shape="round" className="w-[40px] border">
                                <Img src="/images/img_search.svg" />
                            </Button> */}
                        </div>
                    </div>
                    <div className="overflow-hidden md:overflow-auto">
                        <Tabs className={""}>
                            <TabList className="flex border-b border-t border-gray-300 gap-4 px-4 overflow-hidden">
                                <Tab className="text-sm mr-4 p-2 cursor-pointer text-gray-800" selectedClassName="border-b-2 font-semibold border-blue-500 text-blue-400">Assigned Courses</Tab>
                                <Tab className="text-sm mr-4 p-2 cursor-pointer text-gray-800" selectedClassName="border-b-2 font-semibold border-blue-500 text-blue-400">Students</Tab>
                                <Tab className="text-sm mr-4 p-2 cursor-pointer text-gray-800" selectedClassName="border-b-2 font-semibold border-blue-500 text-blue-400">Instructors</Tab>
                                <Tab className="text-sm mr-4 p-2 cursor-pointer text-gray-800" selectedClassName="border-b-2 font-semibold border-blue-500 text-blue-400">Classroom Performance</Tab>
                                <Tab className="text-sm mr-4 p-2 cursor-pointer text-gray-800" selectedClassName="border-b-2 font-semibold border-blue-500 text-blue-400">Student Performance</Tab>
                                <Tab className="text-sm mr-4 p-2 cursor-pointer text-gray-800" selectedClassName="border-b-2 font-semibold border-blue-500 text-blue-400">Assignments</Tab>
                            </TabList>
                            <TabPanel className={"overflow-hidden"}>
                                {isLoading ? (
                                    <></>
                                ) : (
                                    <AssignedCourses classroomData={classroomDetails ?? {}} reloadClassroomDetails={fetchData} />
                                )}
                            </TabPanel>
                            <TabPanel className={"overflow-hidden"}>
                                {isLoading ? (
                                    <></>
                                ) : (
                                    <Students classroomData={classroomDetails ?? {}} />
                                )}
                            </TabPanel>
                            <TabPanel className={"overflow-hidden"}>
                                {isLoading ? (
                                    <></>
                                ) : (
                                    <Instructors classroomData={classroomDetails ?? {}} />
                                )}
                            </TabPanel>
                            <TabPanel className={"overflow-hidden"}>
                                {isLoading ? (
                                    <></>
                                ) : (
                                    <ClassroomPerformance classroomData={classroomDetails ?? {}} />
                                )}
                            </TabPanel>
                            <TabPanel className={"overflow-hidden"}>
                                {isLoading ? (
                                    <></>
                                ) : (
                                    <StudentPerformance classroomData={classroomDetails ?? {}} />
                                )}
                            </TabPanel>
                            <TabPanel className={"overflow-hidden"}>
                                {isLoading ? (
                                    <></>
                                ) : (
                                    <Assignments classroomData={classroomDetails ?? {}} />
                                )}
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
}
