import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const StartupProgressList = ({ startupSimData, height }) => {
    useEffect(() => {
        if (startupSimData ) {
            
        }
    }, [startupSimData]);

    return (
        <div>
            <h2>Situations, Choices, Decisions, and Results</h2>
            <br/>
            {(startupSimData.length) ? (
                <ol>
                    {startupSimData.map((item, index) => { 
                        const selectedText = `choice${item.choiceDecision}Text`;
                        const selectedMetric = `choice${item.choiceDecision}Metric`;
                        const selectedAffect = `choice${item.choiceDecision}Affect`;
                        return (
                            <li key={item.id}>
                                <h2>{index+1}. {item.situation}</h2>
                                {item.choiceDecision &&
                                    <p>----{item[selectedText]} -- {item[selectedMetric]} -- {item[selectedAffect]}</p>
                                }
                            </li>
                        );
                    })}
                </ol>
            ) : (
                <p>No Decisions Yet</p>
            )}
        </div>
    );
};

export default StartupProgressList;