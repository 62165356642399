
const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

export const fetchStatus = async (classroomId) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/api/StartupSim/${classroomId}/Status`, {
            method: 'GET',
            credentials: 'include',
            mode: "cors",
            headers: {
                'accept': 'application/json', // Adjust the 'accept' header as needed
            },
        });

        var data = {}
        if (response.ok) {  // Check if the response status is 200-299
          data = await response.json(); // Parse the response as JSON
          console.log(data); // 'data' contains the object from the API response
        } else {
          console.error('Error:', response.status, response.statusText);
          throw { status: response.status, message: response.statusText};
        }

        //const data = await response.json();
        return data;
    }
    catch {
        console.error("Couldn't get info")
    }
}

export async function postSettings(classroomId, settingData) {

    const url = `${API_ENDPOINT}/api/StartupSim/${classroomId}/SetOptions`;

    const response = await fetch(url, {
        method: 'POST',
        mode: "cors",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(settingData),
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw { status: response.status, message: errorText};
    }

    const responseData = await response;
    return responseData;
}

export async function postBeginStartup(studentId, startupType) {

    const url = `${API_ENDPOINT}/api/StartupSim/${studentId}/Begin/${startupType}`;

    const response = await fetch(url, {
        method: 'POST',
        mode: "cors",
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw { status: response.status, message: errorText};
    }

    const responseData = await response.json();
    return responseData;
}

export const fetchResumeStartup = async (studentId) => {
  try {
      const response = await fetch(`${API_ENDPOINT}/api/StartupSim/${studentId}/Resume`, {
          method: 'GET',
          credentials: 'include',
          mode: "cors",
          headers: {
              'accept': 'application/json', // Adjust the 'accept' header as needed
          },
      });

      var data = {}
      if (response.ok) {  // Check if the response status is 200-299
        data = await response.json(); // Parse the response as JSON
        console.log(data); // 'data' contains the object from the API response
      } else {
        console.error('Error:', response.status, response.statusText);
        throw { status: response.status, message: response.statusText};
      }

      //const data = await response.json();
      return data;
  }
  catch {
      console.error("Couldn't get info")
  }
}

export async function postContinueStartup(studentId, decision) {

    const url = `${API_ENDPOINT}/api/StartupSim/${studentId}/Continue/${decision}`;

    const response = await fetch(url, {
        method: 'POST',
        mode: "cors",
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw { status: response.status, message: errorText};
    }

    const responseData = await response.json();
    return responseData;
}

