import React, {useEffect, useState} from "react";
import Popup from 'reactjs-popup';
import { Text, Button, Heading, Img, Input } from "..";
import { IconAlertCircle } from "@tabler/icons-react";
import StartupSimSettings from "components/StartupSimSettings";

export default function StartupSimSettingsPopup({ open, onClose, onClassCreated }) {
  const [selectedDifficulty, setSelectedDifficulty] = useState('')
  const [dueDate, setDueDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state to track submission status
  const [successMessage, setSuccessMessage] = useState(''); // New state for success message
  const [error, setError] = useState(null);
  const handleUpdateSettings = async () => {
  };    

  return (
    <Popup open={open} onClose={onClose} modal contentStyle={{ width: '400px', height: '450px', borderRadius: '1rem' }}>
      <StartupSimSettings />
    </Popup>
  );
}
